import React, { useState, useEffect, createContext } from "react";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [language, setLanguage] = useState({ code: "rs", isLoaded: false});
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); 

    const handleLanguageChange = langCode => setLanguage(prevLang => ({...prevLang, code: langCode}));

    const toggleMenu = () => setIsMenuOpen(prev => !prev);
    const closeMenu = () => {
        if (isMenuOpen) setIsMenuOpen(false);
    };

    const toggleDropdown = () => setIsDropdownOpen(prev => !prev);
    const closeDropdown = () => {
        if (isDropdownOpen) setIsDropdownOpen(false)
    };

    const openSansFontsForRussian = language.code === "ru" ? "use-open-sans-fonts" : "";

    useEffect(() => {
        const storageLanguage = localStorage.getItem("language_code");
        setLanguage(prevLang => 
            storageLanguage 
                ? { code: storageLanguage, isLoaded: true }
                : {...prevLang, isLoaded: true }
        );
    }, [])

    useEffect(() => {
        if (language.isLoaded) {
            localStorage.setItem("language_code", language.code);
        }
    }, [language.code])

    return (
        <AppContext.Provider
            value={{
                language,
                isMenuOpen,
                isDropdownOpen,
                openSansFontsForRussian,
                handleLanguageChange,
                toggleMenu, 
                closeMenu,
                toggleDropdown,
                closeDropdown,
            }}
        >
            {children}
        </AppContext.Provider>

    );
};


const RootElement = ({ children }) => (
    <AppProvider>
        {children}
    </AppProvider>
);

export default RootElement;
